import React from 'react';
import Layout from '../components/layouts/Layout';
import { FaRegCheckCircle } from '@react-icons/all-files/fa/FaRegCheckCircle';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'gatsby';

const SuccessfullyPaid = () => {
  return (
    <Container fluid="md" className="pt-4">
      <Row>
        <Col className="mb-5 ">
          <div className="bg-white p-2 d-flex flex-column p-4 align-items-center">
            <h4>Плащането е успешно!</h4>
            <div className="my-3">
              <FaRegCheckCircle size="100" color="green"></FaRegCheckCircle>
            </div>
            <Link to="/">
              <button className="btn animated">
                <span>Начало</span>
              </button>
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

const AddProperty = () => {
  return (
    <Layout navColor="white" margined={true}>
      <SuccessfullyPaid></SuccessfullyPaid>
    </Layout>
  );
};

export default AddProperty;
